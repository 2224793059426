<template>
  <button
    class="insert-link-button button button--secondary"
    type="button"
    data-test-id="insert-link-button"
    @click="handleClick"
  >
    <icon-component name="link" class="insert-link-button__icon button__icon" />
    <slot>Link</slot>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InsertLinkButton',
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userIsUnverified',
    ]),
  },
  methods: {
    handleClick(event) {
      if (this.userIsLoggedIn && this.userIsUnverified) {
        this.$store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
        return;
      }
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog', { isUGC: true });
        return;
      }
      this.$snowplow.trackButtonEvent({
        data: {
          type: 'insert link',
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_insert-link-button';
</style>
