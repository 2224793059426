<template>
  <form-component
    class="insert-link-form"
    @submit.prevent="handleSubmit"
  >
    <text-input
      id="urlForLink"
      v-model="url"
      class="text-input--full-width"
    >
      Url
    </text-input>
    <text-input
      id="textForLink"
      v-model="text"
      class="text-input--full-width"
    >
      Text
    </text-input>
    <div class="align-center">
      <button-component
        type="submit"
        button-type="create link"
        :disabled="!isValidUrl"
        @click="handleSubmit"
      >
        Save
      </button-component>
    </div>
  </form-component>
</template>

<script>
import TextInput from '@/components/Inputs/TextInput.vue';
import ButtonComponent from '@/components/Global/ButtonComponent.vue';

export default {
  name: 'ImageUploadForm',
  components: {
    TextInput,
    ButtonComponent,
  },
  props: {
    selectionData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      url: 'https://',
      text: '',
    };
  },
  computed: {
    isValidUrl() {
      return this.testUrl(this.url);
    },
  },
  mounted() {
    this.text = this.selectionData.selectedString;
    if (this.testUrl(this.text)) this.url = this.text;
    if (this.selectionData.url) this.url = this.selectionData.url;
  },
  methods: {
    testUrl(url) {
      const urlPattern = new RegExp('^(https:\\/\\/)'
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
        + '((\\d{1,3}\\.){3}\\d{1,3}))'
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
        + '(\\?[;&a-z\\d%_.~+=-]*)?'
        + '(\\#[-a-z\\d_]*)?$', 'i');
      return !!urlPattern.test(url);
    },
    buildLink() {
      const linkTag = document.createElement('a');
      linkTag.setAttribute('href', this.url);
      linkTag.setAttribute('target', '_blank');
      linkTag.setAttribute('rel', 'noopener noreferrer ugc');
      linkTag.innerHTML = this.text;
      return linkTag;
    },
    handleSubmit() {
      if (!this.text) {
        // if there is no text then make the text the url
        this.text = this.url;
      }
      this.$emit('submit', { linkTag: this.buildLink(), selectionData: this.selectionData });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_insert-link-form';
</style>
