<template>
  <dialog-base
    class="dialog-base--insert-link"
    @close="close"
  >
    <template v-slot:heading>Add link</template>
    <insert-link-form
      :selection-data="selectionData"
      @submit="setLink"
      @close="close"
    />
  </dialog-base>
</template>

<script>
import DialogBase from '@/components/Global/DialogBase.vue';
import InsertLinkForm from '@/components/InsertLink/InsertLinkForm.vue';

export default {
  name: 'InsertLinkDialog',
  components: {
    DialogBase,
    InsertLinkForm,
  },
  props: {
    selectionData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setLink(event) {
      this.$emit('submit', event);
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
