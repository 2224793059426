import InsertLinkButton from '@/components/InsertLink/InsertLinkButton.vue';
import InsertLinkDialog from '@/components/InsertLink/InsertLinkDialog.vue';
import debounce from 'lodash/debounce';
import { isFeatureFlagEnabled } from '@/utils/utils';

export default {
  components: {
    InsertLinkButton,
    InsertLinkDialog,
  },
  data() {
    return {
      insertLinkDialogIsOpen: false,
      selectionData: {},
      disableInsertLinkButton: false,
    };
  },
  mounted() {
    document.addEventListener('selectionchange', debounce(this.setInsertLinkButtonDisabled, 250));
  },
  beforeDestroy() {
    document.removeEventListener('selectionchange', debounce(this.setInsertLinkButtonDisabled, 250));
  },
  computed: {
    enableLinkButton() {
      return isFeatureFlagEnabled(this.$site, 'enable_links_in_comments');
    },
  },
  methods: {
    setInsertLinkButtonDisabled() {
      this.disableInsertLinkButton = !this.selectionIsValidForLinking(window.getSelection());
    },
    selectionIsValidForLinking(selection) {
      const contentEditableInputElement = this.$refs.contentEditableInput?.$el;
      const selectionIsWithinInput = contentEditableInputElement && [
        selection.anchorNode,
        selection.anchorNode?.parentNode,
        selection.anchorNode?.parentNode?.parentNode,
      ].includes(contentEditableInputElement);

      // If the users selection happens to be elsewhere on the page then we allow them
      // to insert a link at the end of the content
      if (!selectionIsWithinInput) {
        return true;
      }

      // We don't allow link insertion if the selection is within a mention or if it's
      // spaning multiple nodes (ie. if it crosses from text into another link or mention)
      const selectionSpansMultipleNodes = selection.anchorNode !== selection.focusNode;
      const selectionIsWithinAMention = selection.anchorNode.parentNode?.classList?.contains('mention-box__mention');

      return !selectionSpansMultipleNodes && !selectionIsWithinAMention;
    },
    setSelectionData(selection) {
      const selectionIsWithinALink = selection.anchorNode?.parentNode?.tagName === 'A';
      const contentEditableInputElement = this.$refs.contentEditableInput?.$el;
      const selectionIsWithinInput = contentEditableInputElement && [
        selection.anchorNode,
        selection.anchorNode?.parentNode,
        selection.anchorNode?.parentNode?.parentNode,
      ].includes(contentEditableInputElement);

      // If the selection is within a link then we'll edit that link's values
      if (selectionIsWithinALink) {
        const linkNode = selection.anchorNode.parentNode;
        const linkContent = linkNode.innerHTML;

        return {
          selectedString: linkContent,
          offsets: [0, 0],
          anchorNode: linkNode,
          url: linkNode.href,
        };
      }

      // If the user's selection is elsewhere on the page then we'll
      // provide generic selection data to insert the link at the end
      // of the input
      if (!selectionIsWithinInput) {
        return {
          selectedString: '',
          offsets: [0, 0],
          anchorNode: contentEditableInputElement,
        };
      }

      // Otherwise we'll use the selection values
      return {
        selectedString: selection.toString(),
        offsets: [selection.anchorOffset, selection.focusOffset].sort((a, b) => a - b),
        anchorNode: selection.anchorNode,
      };
    },
    openInsertLinkDialog() {
      const selection = window.getSelection();

      // Prevent any link insertion within a mention or any other element besides a link
      // This is a redundant safegaurd that matches the conditions for enabling/disabling
      // the link button.
      if (!this.selectionIsValidForLinking(selection)) return;

      this.selectionData = this.setSelectionData(selection);
      this.insertLinkDialogIsOpen = true;
    },
    setUserCursorAfterNode(node) {
      const range = document.createRange();
      range.setStartAfter(node);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    },
    insertLink(event) {
      const { linkTag, selectionData } = event;
      const theNodeToBeAltered = selectionData.anchorNode;
      const contentEditableInputElement = this.$refs.contentEditableInput?.$el;
      const theNodeIsTheContentEditableInput = theNodeToBeAltered === contentEditableInputElement;
      const theNodeIsALinkTag = theNodeToBeAltered.tagName === 'A';

      // We'll do the link insertion on a clone of the input DOM instead of the actual
      // input DOM. The purpose of this is to avoid any potential interference with
      // the input's v-model binding.
      const nodeIndex = [...contentEditableInputElement.childNodes].indexOf(theNodeToBeAltered);
      const cloneOfContentEditableInput = contentEditableInputElement.cloneNode(true);
      const cloneOfNodeToBeAltered = cloneOfContentEditableInput.childNodes[nodeIndex];

      if (theNodeIsTheContentEditableInput) {
        // If the node is the input itself then we can simply append the link within it
        cloneOfContentEditableInput.appendChild(linkTag);
      } else if (theNodeIsALinkTag) {
        // If the node is a link then we want to fully replace it
        cloneOfNodeToBeAltered.replaceWith(linkTag);
      } else {
        // Otherwise we need cut the node up, insert the link, and put it back together
        const existingNodeContent = cloneOfNodeToBeAltered.wholeText;
        const contentBeforeTheLink = existingNodeContent.slice(0, selectionData.offsets[0]);
        const contentAfterTheLink = existingNodeContent.slice(selectionData.offsets[1]);

        // Insert the three pieces in reverse order. Why reverse order? Because
        // the method is `insertBefore` and not `insertAfter`
        cloneOfNodeToBeAltered.nodeValue = contentAfterTheLink;
        cloneOfContentEditableInput.insertBefore(linkTag, cloneOfNodeToBeAltered);
        cloneOfContentEditableInput.insertBefore(document.createTextNode(contentBeforeTheLink), linkTag);
      }

      // We set the input value to the content of the clone
      // and v-model takes care of the rest.
      this.mentionContent = cloneOfContentEditableInput.innerHTML;

      // Last step is to place the users cursor at the end of the new link.
      // Must happen in nextTick so that DOM can update
      this.$nextTick(() => {
        const newLinkTagIndex = [...cloneOfContentEditableInput.childNodes].indexOf(linkTag);
        const newLinkTag = contentEditableInputElement.childNodes[newLinkTagIndex];
        this.setUserCursorAfterNode(newLinkTag);
      });
    },
    closeLinkDialog() {
      this.insertLinkDialogIsOpen = false;
    },
  },
};
